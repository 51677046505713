import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import {
  AspectRatio,
  Col,
  Container,
  Figure,
  Row,
  Spacer,
  Typography,
} from "@hurleymc/components"
import { selectLink } from "@hurleymc/sdk/selectors"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "../components/link"
import PageBuilder from "../components/page-builder"
import EventDateTime from "../components/event-date-time"

const Card = styled.div`
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
    width: 100%;
  }

  td {
    padding: 0;
  }

  td * {
    line-height: 1.25;
  }

  td:first-child {
    padding-right: 0.5rem;
    text-align: center;
  }

  td:last-child {
    width: 100%;
  }

  tr {
    background: transparent !important;
  }

  .fas {
    font-size: 1.25rem;
  }

  .fa-calendar-alt {
    color: ${({ theme }) => theme.colors.named.curiousBlue};
  }
`

const Events = ({ data }) => {
  const { craft } = data
  const { entries, events } = craft
  const entry = entries[0]
  return (
    <Layout>
      <SEO title={entry.title} />
      <Spacer margin="2rem .5rem">
        <Container>
          <Row>
            <Spacer margin="2rem .5rem">
              <Spacer margin="0 0 1.5rem">
                <Typography variant="heading-6">Events</Typography>
              </Spacer>
              <Spacer margin="0 0 1.5rem">
                <Typography variant="heading-2">{entry.header}</Typography>
              </Spacer>
              {entry.summary && (
                <Typography
                  component="div"
                  dangerouslySetInnerHTML={{ __html: entry.summary }}
                  variant="body"
                />
              )}
            </Spacer>
          </Row>
          <Row>
            {events.map((event, key) => {
              const startDate = event.startDate
                ? new Date(event.startDate)
                : false
              const startTime = event.startTime
                ? new Date(event.startTime)
                : false
              const additionalTime = event.additionalTime
                ? new Date(event.additionalTime)
                : false
              const endDate = event.endDate ? new Date(event.endDate) : false
              const endTime = event.endTime ? new Date(event.endTime) : false

              const registerProps = selectLink({
                linkText: event.linkText,
                to: event.to,
              })
              const eventProps = registerProps.href
                ? { href: registerProps.href }
                : { to: `events/${event.slug}` }

              return (
                <Col key={key} md={1 / 3}>
                  <Spacer margin="0 0 2rem">
                    <Card>
                      {event.previewImage && event.previewImage.length > 0 && (
                        <Spacer margin="0 0 1.5rem">
                          <Link {...eventProps}>
                            <AspectRatio ratio="4:3">
                              <Figure {...event.previewImage[0]} />
                            </AspectRatio>
                          </Link>
                        </Spacer>
                      )}
                      <Spacer margin="0 0 1rem">
                        <Typography
                          component={Link}
                          variant="heading-4"
                          {...eventProps}
                        >
                          {event.title}
                        </Typography>
                      </Spacer>

                      <table>
                        <tbody>
                          {startDate && (
                            <tr>
                              <td>
                                <i className="fas fa-calendar-alt" />
                              </td>
                              <td>
                                <EventDateTime
                                  component={Link}
                                  displayDate={event.displayDate}
                                  startDate={startDate}
                                  startTime={startTime}
                                  additionalTime={additionalTime}
                                  endDate={endDate}
                                  endTime={endTime}
                                  {...eventProps}
                                />
                              </td>
                            </tr>
                          )}
                          {event.eventLocationName && (
                            <tr>
                              <td>
                                <a
                                  href={event.eventUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="material-icons">location_on</i>
                                </a>
                              </td>
                              <td>
                                <Typography
                                  color="default"
                                  component="a"
                                  href={event.eventUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  variant="button"
                                >
                                  {event.eventLocationName}
                                </Typography>
                              </td>
                            </tr>
                          )}
                          {event.to &&
                            event.to.length > 0 &&
                            event.to.map((e, idx) => (
                              <tr key={`events-row-${idx}`}>
                                <td>
                                  <Link to={e.uri} href={e.externalUrl}>
                                    {e.externalUrl ? (
                                      <i className="fas fa-external-link-alt" />
                                    ) : (
                                      <i className="fas fa-link" />
                                    )}
                                  </Link>
                                </td>
                                <td>
                                  <Typography
                                    color="default"
                                    component={Link}
                                    variant="button"
                                    to={e.uri}
                                    href={e.externalUrl}
                                  >
                                    {e.title}
                                  </Typography>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </Card>
                  </Spacer>
                </Col>
              )
            })}
          </Row>
        </Container>
      </Spacer>
      {entry && entry.pageBuilder && (
        <PageBuilder
          pageBuilder={entry.pageBuilder}
          location={{ pathname: entry.uri }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query Events($id: [Craft_QueryArgument]!) {
    craft {
      entries(site: "hurleyFoundation", id: $id) {
        __typename
        title
        uri
        ... on Craft_events_events_Entry {
          header
          pageBuilder {
            ...PageBuilder
          }
          summary
          title
        }
      }
      events: entries(
        site: "hurleyFoundation"
        section: "event"
        orderBy: "startDate ASC"
      ) {
        id
        title
        uri
        ... on Craft_event_event_Entry {
          slug
          additionalTime
          displayDate
          endDate
          endTime
          eventLocationName
          eventUrl
          header
          linkText
          previewImage {
            ... on Craft_AssetInterface {
              title
              url
              xs: url(transform: "defaultContentXs", immediately: true)
              sm: url(transform: "defaultContentSm", immediately: true)
              md: url(transform: "defaultContentMd", immediately: true)
              lg: url(transform: "defaultContentLg", immediately: true)
              xl: url(transform: "defaultContentXl", immediately: true)
            }
          }
          startDate
          startTime
          title
          to {
            ...Link
          }
        }
      }
    }
  }
`

export default Events
